import { useQuery } from "@tanstack/react-query";
import { GetListApiResponseSuccess } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { getBillingProducts, getProductsCostsDefinition } from "services/walletManagement/product";
import { BillingProductRaadModel, CostsBillingParametersRadModel } from "services/walletManagement/product/Types/billingProductReadModel";

export function useGetBillingProduct() {
    const { token } = useIdentity();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-products-billing'],
        queryFn: async (): Promise<GetListApiResponseSuccess<BillingProductRaadModel>> => {
            const { data } = await getBillingProducts(token!);
            return data;
        },
    });
}

export function useGetProductsCostsDefinition() {
    const { token } = useIdentity();

    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-products-costs'],
        queryFn: async (): Promise<GetListApiResponseSuccess<CostsBillingParametersRadModel>> => {
            const { data } = await getProductsCostsDefinition(token!);
            return data; 
        },
    });
}
