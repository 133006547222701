import { billingAxiosApi } from "services/axiosApi";
import { BillingProductRaadModel, CostsBillingParametersRadModel } from "./Types/billingProductReadModel";
import { GetListApiResponseSuccess } from "contexts/apiRequestContext";

const url = '/Product'; 

export async function getBillingProducts(token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await billingAxiosApi.get<GetListApiResponseSuccess<BillingProductRaadModel>>(`${url}/Billing`, config);
}

export async function getProductsCostsDefinition(token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await billingAxiosApi.get<GetListApiResponseSuccess<CostsBillingParametersRadModel>>(`${url}/CostsDefinition`, config);
}