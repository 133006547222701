import { Stack } from '@mui/material';
import { AddIcon, AnnouncementIcon, BookCheckIcon, BookDownloadIcon, DocExcelIcon, InvoiceReceivableIcon, ReceiveIcon, TaskDownloadIcon, TransferIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { isEmpty } from 'lodash';
import React from 'react';
import { activeTheme } from 'services/theme';
import { BillingFull } from 'services/walletManagement/billing/billing.types';

interface IChargesListHeaderProps {
    isFetching: boolean;
    refetch: () => void;
    isMenuCharges: boolean;
    handleAction: (actionName: string) => void
    rowDataByItemsSelected: BillingFull[]
    handleDownloadBatch: () => void
    exportBillingAsCSVAsync: () => void;
    exportBillingAsCnabAsync: () => void;
    enableExportBilling: boolean
}

const theme = activeTheme();
const ChargesListHeader: React.FC<IChargesListHeaderProps> = ({
    isFetching,
    refetch,
    isMenuCharges,
    handleAction,
    rowDataByItemsSelected,
    handleDownloadBatch,
    exportBillingAsCSVAsync,
    exportBillingAsCnabAsync,
    enableExportBilling,
}) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const hasItemSelected = rowDataByItemsSelected?.length > 0;

    const enableDownloadBacth = hasItemSelected && rowDataByItemsSelected?.every((x, i) => {
        return x?.registered &&
            !isEmpty(x?.bankSlip?.id) &&
            x?.walletCode === rowDataByItemsSelected[0]?.walletCode;
    });

    const isNotBaixadoAndNotSettlement = hasItemSelected && rowDataByItemsSelected?.every(({ bankSlip }) =>
        bankSlip?.statusRegisterValue?.toLowerCase() !== 'baixado' &&
        bankSlip?.statusSettlementValue?.toLowerCase() !== 'settlement'
    );

    const enableBankSlipCancel = hasItemSelected && rowDataByItemsSelected.every(({ bankSlip }) => {
        return bankSlip?.statusRegisterValue?.toLowerCase() === 'registradocip' &&
            bankSlip?.statusSettlementValue?.toLowerCase() !== 'settlement'
    });

    const enableTransferWalletCode = hasItemSelected && rowDataByItemsSelected.every((x) => {
        return isNotBaixadoAndNotSettlement && x?.walletCode === rowDataByItemsSelected[0]?.walletCode
    });

    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Cobranças
            </Typography>
            <Actions
                buttonsActionsList={[
                    {
                        enable: isMenuCharges,
                        label: 'Nova cobrança',
                        action: () => handleAction('newCharges'),
                        icon: <AddIcon />,
                    },
                    {
                        label: "Atualizar",
                        action: refetch,
                        icon: <UpdateIcon htmlColor={color} sx={iconSx} />,
                        enable: true,
                        disabled: false
                    },
                    {
                        enable: isMenuCharges,
                        label: 'Importar arquivo CNAB',
                        action: () => handleAction('generateCnab'),
                        icon: <InvoiceReceivableIcon />,
                    },
                    {
                        disabled: !enableExportBilling,
                        enable: true,
                        label: 'Exportar cobranças',
                        icon: <TaskDownloadIcon htmlColor={color} />,
                        groupButtons: [
                            {
                                enable: true, 
                                label: "CSV", 
                                action: exportBillingAsCSVAsync, 
                                icon: <DocExcelIcon htmlColor={color} />,
                            }, 
                            {
                                enable: true, 
                                label: "CNAB", 
                                action: exportBillingAsCnabAsync, 
                                icon: <BookCheckIcon htmlColor={color} />,
                            }, 
                        ]
                    },
                    {
                        disabled: !enableDownloadBacth,
                        enable: true,
                        label: 'Download cobranças',
                        action: handleDownloadBatch,
                        icon: <BookDownloadIcon htmlColor={color} />,
                    },
                    {
                        disabled: !enableTransferWalletCode,
                        enable: true,
                        label: 'Transferir carteira',
                        action: () => handleAction('transferWallet'),
                        icon: <TransferIcon htmlColor={color} />,
                    },
                    {
                        disabled: !enableBankSlipCancel,
                        enable: true,
                        label: 'Solicitar baixa',
                        action: () => handleAction('bankSlipCancel'),
                        icon: <ReceiveIcon htmlColor={color} />,
                    },
                    {
                        disabled: !isNotBaixadoAndNotSettlement,
                        enable: true,
                        label: 'Atualizar instrução',
                        action: () => handleAction('updateInstructionDebtCollection'),
                        icon: <AnnouncementIcon htmlColor={color} />,
                    },
                ]}
                numberOfButtons={4}
            />
        </Stack>
    );
};
export default ChargesListHeader;
