import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import React, { Fragment } from 'react'
import { BillingProductRaadModel } from 'services/walletManagement/product/Types/billingProductReadModel';
import { ProductContent } from '../ProductContent';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable } from '@uy3/web-components';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { RefreshProgress } from 'components/RefreshProgress';

type BillingProductProps = {
    queryData?: GetListApiResponseSuccess<BillingProductRaadModel>
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    rowsPerPage: number;
    page: number;
    refetch: () => void;
    isLoading: boolean;
    isFetching: boolean;
}

export const BillingProduct = ({ queryData, isLoading, page, refetch, rowsPerPage, setPage, setRowsPerPage, isFetching }: BillingProductProps) => {
    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: 'Código',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'bankAccountDebtCosts',
            headerName: 'Conta tarifa',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'enableAutoSubmitToDebtCollection',
            headerName: 'Habilitar envio automatico cartorio',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => !!value ? "Sim" : "Não" 
        },
        {
            field: 'daysOverdueLimit',
            headerName: 'Dias limite pagamento',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'billingTypeValueDisplay',
            headerName: 'Tipo cobrança',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'interestCodeValueDisplay',
            headerName: 'Codigo Juros',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        }
    ];

    return (
        <ProductContent
            title='Produtos de cobrança'
            description='Confira na listagem abaixo os produtos de cobrança cadastrados.'
            isFetching={isFetching}
            refetch={refetch}
        >
            <Fragment>
                <RefreshProgress refreshing={isFetching} />
                <ListSkeleton isLoading={isLoading}>
                    <DataTable
                        columns={columns}
                        rows={(queryData?.data ?? [])}
                        page={queryData ? queryData.page ?? 0 : page}
                        rowCount={queryData?.totalItems ?? 0}
                        pageCount={queryData?.totalPages ?? 0}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </ListSkeleton>
            </Fragment>
        </ProductContent>
    )
}
