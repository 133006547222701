import { GetListApiResponseSuccess } from 'contexts/apiRequestContext'
import React, { Fragment } from 'react'
import type { CostsBillingParametersRadModel } from 'services/walletManagement/product/Types/billingProductReadModel'
import { ProductContent } from '../ProductContent'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { DataTable } from '@uy3/web-components'
import { ListSkeleton } from 'components/Skeleton/ListSkeleton'
import { RefreshProgress } from 'components/RefreshProgress'
import { formatCurrencyInCents } from 'helpers'

type CostsBillingParametersProps = {
  queryData?: GetListApiResponseSuccess<CostsBillingParametersRadModel>
  setRowsPerPage: (page: number) => void;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  page: number;
  refetch: () => void;
  isLoading: boolean;
  isFetching: boolean;
}

export const CostsBillingParameters = ({ queryData, page, rowsPerPage, setPage, setRowsPerPage, isLoading, refetch, isFetching }: CostsBillingParametersProps) => {

  function formatValueRenderCell({value}: GridRenderCellParams<any, any, any>) {
    return formatCurrencyInCents(value); 
  }

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Código',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: ({ value }) => value ?? "N/D"
    },
    {
      field: 'register',
      headerName: 'Custo registro',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'cancellation',
      headerName: 'Custo baixa',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'discount',
      headerName: 'Desconto',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'settlement',
      headerName: 'Custo liquidacao',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'debtCollection',
      headerName: 'Custo protesto',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'debtCollectionSettlement',
      headerName: 'Custo liquidacao cartorio',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'debtCollectionCancellation',
      headerName: 'Custo sustar protesto',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'debtCollectionSuccess',
      headerName: 'Custo baixa protesto',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'transferRegister',
      headerName: 'Custo transferencia registro',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'transferCancellation',
      headerName: 'Custo transferencia baixa',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    },
    {
      field: 'overDueCancellation',
      headerName: 'Custo baixa decurso prazo',
      hideSortIcons: true,
      flex: 0.5,
      editable: false,
      renderCell: formatValueRenderCell
    }
  ];

  return (
    <ProductContent
      title='Custos e parâmetros de cobrança'
      description='Confira na listagem abaixo os custos e parâmetros de cobranças cadastradas.'
      isFetching={isFetching}
      refetch={refetch}
    >
      <Fragment>
        <RefreshProgress refreshing={isLoading} />
        <ListSkeleton isLoading={isFetching}>
          <DataTable
            columns={columns}
            rows={(queryData?.data ?? [])}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </ListSkeleton>
      </Fragment>
    </ProductContent>
  )
}
