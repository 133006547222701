/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@tanstack/react-query';
import { CognitoUserAttribute, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router';
import {
    AuthenticationResponse,
    confirmMfa as cognitoConfirmMfa,
    currentUser,
    signIn as cognitoSign,
    confirmMfaSetup as cognitoConfirmMfaSetup,
    completeNewPasswordChallenge,
    changePassword as cognitoChangePassword,
    sendEmailConfirmationCode as cognitoSendEmailCode,
    confirmEmailCode as cognitoConfirmEmailCode,
    sendCodeForPasswordRecover as cognitoSendCodeForPasswordRecover,
    confirmPasswordRecover as cognitoConfirmPasswordRecover,
    generateSessionId,
} from 'services/identity';
import { ApiResponseError, useApiRequest } from './apiRequestContext';
import { useAppConfig } from './appConfig';

interface User {
    id: string;
    name?: string;
    verified?: boolean;
}

type UserContextProps = {
    user?: User | undefined;
    token: string | undefined;
    authFlow: AuthenticationResponse | undefined;
    submitError: string | undefined;
    clearSubmitError: () => void;
    logout: () => void;
    signIn: (email: string, password: string) => Promise<AuthenticationResponse>;
    changePassword: (password: string, oldPassword: string) => Promise<AuthenticationResponse>;
    confirmMfa: (code: string) => Promise<AuthenticationResponse>;
    sendEmailConfirmationCode: () => void;
    confirmEmailCode: (code: string) => Promise<AuthenticationResponse>;
    sendCodeForPasswordRecover: (email: string) => void;
    confirmPasswordRecover: (
        email: string,
        code: string,
        password: string
    ) => Promise<AuthenticationResponse>;
    isAuthenticated: () => boolean;
    mfaSecret: () => string | undefined;
    confirmMfaSetup: (code: string) => Promise<AuthenticationResponse>;
    navigateToFlow: (
        navigate: NavigateFunction,
        onError?: (value: React.SetStateAction<string | undefined>) => void
    ) => void;
    confirmPassword: (email: string, password: string) => Promise<AuthenticationResponse>;
    userSubId: string | undefined;
};

const UserContext = createContext<UserContextProps>({
    user: undefined,
    token: undefined,
    authFlow: undefined,
    submitError: undefined,
    logout: () => 0,
    clearSubmitError: () => undefined,
    isAuthenticated: () => false,
    mfaSecret: () => undefined,
    sendEmailConfirmationCode: () => undefined,
    sendCodeForPasswordRecover: () => undefined,
    navigateToFlow: () => undefined,
    signIn: () => {
        throw Error('UserContext not initialized');
    },
    changePassword: () => {
        throw Error('UserContext not initialized');
    },
    confirmMfa: () => {
        throw Error('UserContext not initialized');
    },
    confirmMfaSetup: () => {
        throw Error('UserContext not initialized');
    },
    confirmEmailCode: () => {
        throw Error('UserContext not initialized');
    },
    confirmPasswordRecover: () => {
        throw Error('UserContext not initialized');
    },
    confirmPassword: () => {
        throw Error('UserContext not initialized');
    },
    userSubId: undefined,
});

interface IdentityProviderProps {
    children: JSX.Element;
}

const mapCurrentUser = () => {
    var cur = currentUser();
    if (cur) {
        return { id: cur.getUsername() };
    }
    return undefined;
};

const processUserAttributes = (result: CognitoUserAttribute[] | undefined) => {
    const verifiedAttrs =
        result?.filter((attr) => attr.getName().endsWith('_verified') && !!attr.getValue()) ?? [];

    const name = result
        ?.find((attr) => attr.getName().endsWith('name') && !!attr.getValue())
        ?.getValue();

    return {
        name,
        verified: verifiedAttrs?.length > 0,
    };
};

const loadTokenAndVerifiedAttrs = (
    user: any,
    setUser: any,
    setToken: any,
    userSubId: string | undefined,
    setUserSubId: any
) => {
    const cur = currentUser();
    cur?.getSession((error: Error | null, session: CognitoUserSession | null) => {
        if (error || !session?.isValid()) {
            console.log('UserContext - loadTokenAndVerifiedAttrs - getSession error', error);
            setUser(undefined);
        }
        let sessToken = session?.getAccessToken().getJwtToken();
        setToken(sessToken);

        cur?.getUserAttributes((err, result) => {
            if (err) {
                console.log(
                    'UserContext - loadTokenAndVerifiedAttrs - error on getUserAttributes',
                    err
                );
            }
            if (user?.verified !== true) {
                setUser((prevUser: any) => {
                    return { ...prevUser, ...processUserAttributes(result) };
                });
            }
        });

        const sub = session?.getAccessToken()?.payload?.sub;
        if (!userSubId || userSubId !== sub) {
            setUserSubId(sub);
        }
    });
};

export const IdentityProvider = ({ children }: IdentityProviderProps) => {
    const { appConfig } = useAppConfig();
    const [user, setUser] = useState<User | undefined>(mapCurrentUser());
    const [userSubId, setUserSubId] = useState<string | undefined>(undefined);
    const [authFlow, setAuthFlow] = useState<AuthenticationResponse | undefined>();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [submitError, setSubmitError] = useState<string | undefined>(undefined);

    useEffect(() => {
        console.log('initial token load');
        loadTokenAndVerifiedAttrs(undefined, setUser, setToken, userSubId, setUserSubId);
    }, []);

    useEffect(() => {
        const cur = currentUser();
        const interval = setInterval(() => {
            if (token) {
                cur?.getSession(
                    (sessionError: Error | null, session: CognitoUserSession | null) => {
                        if (sessionError) {
                            console.log('identity context - session not available');
                            logout();
                        }

                        var maybeNewToken = session?.getAccessToken().getJwtToken();
                        if (token !== maybeNewToken) {
                            setToken(maybeNewToken);
                        }
                    }
                );
            }
        }, 3 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [token]);

    useEffect(() => {
        if (authFlow?.type === 'success') {
            console.log('IdentityProvider - loading token for authFlow', { authFlow });
            loadTokenAndVerifiedAttrs(user, setUser, setToken, userSubId, setUserSubId);
        }
        if (authFlow?.type === 'failure') {
            console.log('IdentityProvider - authFlow failure', {
                details: authFlow.failureDetails,
            });
            setSubmitError(authFlow?.failureDetails?.message);
        }
    }, [authFlow, user]);

    const isAuthenticated = () => (user && (!authFlow || authFlow?.type === 'success')) === true;

    const signIn = async (email: string, password: string) => {
        var resp = await cognitoSign(email, password);
        setAuthFlow(resp);
        var cur = mapCurrentUser();
        setUser(cur);
        setSubmitError(undefined);
        return resp;
    };

    const logout = () => {
        currentUser()?.signOut();
        setUser(undefined);
        setAuthFlow(undefined);
    };

    const changePassword = async (
        password: string,
        oldPassword: string
    ): Promise<AuthenticationResponse> => {
        var user = currentUser();
        if (authFlow === undefined && !!user) {
            var respChangePassword = await cognitoChangePassword(user, oldPassword, password);
            setAuthFlow(respChangePassword);
            var cur2 = mapCurrentUser();
            setUser(cur2);
            return respChangePassword;
        }

        return {
            type: 'failure',
            failureDetails: { message: 'Não foi possível alterar a senha. Sessão não encontrada.' },
        };
    };

    const confirmMfaSetup = async (code: string): Promise<AuthenticationResponse> => {
        if (authFlow?.type === 'mfaSetup' || (authFlow?.type === 'failure' && !!authFlow.user)) {
            var resp = await cognitoConfirmMfaSetup(code, authFlow.user!);
            setAuthFlow(resp);
            return resp;
        } else {
            return {
                type: 'failure',
                failureDetails: {
                    message: 'Não foi possível confirmar o MFA. Sessão não encontrada.',
                },
            };
        }
    };

    const mfaSecret = (): string | undefined => {
        if (authFlow?.type === 'mfaSetup') {
            const id = user?.id;
            const code = authFlow.secretCode;
            var totpUri =
                'otpauth://totp/MFA:' +
                id +
                '?secret=' +
                code +
                '&issuer=UYZY ' +
                appConfig.MAIN_TITLE;
            return totpUri;
        }
        return undefined;
    };

    const sendEmailConfirmationCode = async (): Promise<AuthenticationResponse> => {
        var cur = currentUser();
        if (cur) {
            var resp = await cognitoSendEmailCode(cur);
            setAuthFlow(resp);
            return resp;
        } else {
            return {
                type: 'failure',
                failureDetails: {
                    message:
                        'Não foi possível enviar o código de confirmação do e-mail. Sessão não encontrada.',
                },
            };
        }
    };

    const confirmEmailCode = async (emailCode: string): Promise<AuthenticationResponse> => {
        var cur = currentUser();
        if (cur) {
            var resp = await cognitoConfirmEmailCode(cur, emailCode);
            setAuthFlow(resp);
            return resp;
        } else {
            return {
                type: 'failure',
                failureDetails: {
                    message: 'Não foi possível enviar confirmar o e-mail. Sessão não encontrada.',
                },
            };
        }
    };

    const sendCodeForPasswordRecover = async (email: string): Promise<AuthenticationResponse> => {
        var resp = await cognitoSendCodeForPasswordRecover(email);
        setAuthFlow(resp);
        return resp;
    };

    const confirmPasswordRecover = async (
        email: string,
        code: string,
        password: string
    ): Promise<AuthenticationResponse> => {
        var resp = await cognitoConfirmPasswordRecover(email, code, password);
        if (resp.type === 'success') {
            resp = await signIn(email, password);
        }
        setAuthFlow(resp);
        setUser(mapCurrentUser());
        return resp;
    };

    const clearSubmitError = () => setSubmitError(undefined);

    const onSuccessFlow = (navigate: NavigateFunction): void => {
        navigate(localStorage.getItem('urlFromRedirect') ?? '/');
        localStorage.removeItem('urlFromRedirect');
    };

    const navigateToFlow = (navigate: NavigateFunction): void => {
        switch (authFlow?.type) {
            case 'success':
                onSuccessFlow(navigate);
                break;
            case 'mfa':
                navigate('/login/mfa');
                break;
            case 'mfaSetup':
                navigate('/login/mfa/setup');
                break;
            case 'newPassword':
                navigate('/login/confirmar-senha');
                break;
            case 'failure':
                break;
        }
    };

    const confirmMfa = async (code: string): Promise<AuthenticationResponse> => {
        if (authFlow?.type === 'mfa' || (authFlow?.type === 'failure' && !!authFlow.user)) {
            var resp = await cognitoConfirmMfa(authFlow.user, code);
            setAuthFlow(resp);
            setUser(mapCurrentUser());
            return resp;
        }
        throw Error('User is not provided');
    };

    const confirmPassword = async (email: string, password: string) => {
        if (authFlow?.type === 'newPassword' || (authFlow?.type === 'failure' && !!authFlow.user)) {
            let resp = await completeNewPasswordChallenge(email, password, authFlow.user!);
            setAuthFlow(resp);
            let currentUser = mapCurrentUser();
            setUser({
                ...currentUser,
                verified: true,
                id: resp.user?.getUsername() ?? '',
            });
            return resp;
        }
        throw Error('User is not provided');
    };

    return (
        <UserContext.Provider
            value={{
                user,
                token,
                authFlow,
                submitError,
                clearSubmitError,
                logout,
                signIn,
                changePassword,
                confirmMfa,
                isAuthenticated,
                mfaSecret,
                confirmMfaSetup,
                navigateToFlow,
                sendEmailConfirmationCode,
                sendCodeForPasswordRecover,
                confirmPasswordRecover,
                confirmEmailCode,
                confirmPassword,
                userSubId,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

type StartSession = {
    userPassword: string;
    then: (sessionResponse: AxiosResponse<string, any>) => void;
};
export function useGenerateSessionIdMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { appConfig } = useAppConfig();
    const userPoolId = appConfig.USER_POOL_ID;
    const cognitoClientId = appConfig.USER_CLIENT_ID;
    const { user } = useIdentity();

    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (params: StartSession): Promise<any> => {
            startRequest();
            var resp = await generateSessionId(
                user?.id,
                params.userPassword,
                userPoolId,
                cognitoClientId
            );
            params.then(resp);
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateGenerateSessionId: mutate, isLoading };
}

export function useIdentity(): UserContextProps {
    const context = useContext(UserContext);
    const {
        user,
        token,
        authFlow,
        submitError,
        logout,
        signIn,
        changePassword,
        confirmMfa,
        clearSubmitError,
        isAuthenticated,
        mfaSecret,
        confirmMfaSetup,
        navigateToFlow,
        sendEmailConfirmationCode,
        confirmEmailCode,
        sendCodeForPasswordRecover,
        confirmPasswordRecover,
        confirmPassword,
        userSubId,
    } = context;

    return {
        user,
        token,
        authFlow,
        submitError,
        logout,
        signIn,
        changePassword,
        confirmMfa,
        isAuthenticated,
        mfaSecret,
        confirmMfaSetup,
        clearSubmitError,
        navigateToFlow,
        sendEmailConfirmationCode,
        confirmEmailCode,
        sendCodeForPasswordRecover,
        confirmPasswordRecover,
        confirmPassword,
        userSubId,
    };
}
