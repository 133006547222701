import { Item } from '@uy3/web-components';
import { isEmpty } from 'lodash';
import { formatCurrencyInCents, formatMultiply } from 'helpers/formats/Currency';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { WarrantyType } from './generics';
import { PublicPayrollCreateModel, warrantyDescription } from './publicPayrollModel';
import { formatDate } from 'helpers';

export interface IWarrantyReadModel {
    warrantyType: string;
    renavam: string;
    typeOfVehicle: string;
    chassis: string;
    board: string;
    supplier: string;
    taxNumberOfSupplier: string;
    brand: string;
    color: string;
    model: string;
    yearOfManufacture: number;
    yearOfModel: number;
    typeOfFuel: string;
    registryOffice: string;
    registrationNumber: string;
    width: number;
    length: number;
    addressName: string;
    zipCodeAddress: string;
    city: string;
    uf: string;
    district: string;
    addressNumber: string;
    complementAddress: string;
    propertyFeatures: string;
    number: string;
    court: string;
    valueOfCause: number;
    claimant: string;
    claimed: string;
    typeOfProcess: string;
    downPayment: number;
    sellerCompstringName: string;
    sellerRegistrationNumber: string;
    sellerCompanyName?: string;
    valueInCash: string;
    subtotalAmount?: string;
    totalAmountFinancedTaxFree: string;
    register: string;
    type: string;
    splitMethodType: string;
    paymentNetworks: string;
    constituted: boolean;
    totalValue?: string;
    employeeCode: string;
    agreementCode: string;
    employeerInstCode: string;
    payrollAgreementId: string;
    payrollAgreementName: string;
    warrantyCode: string;
    paymentTransferDay: number;
    paymentAmountInCents: number;
    employmentStatusCode: string;
    employmentStatusDescription: string;
    payerName: string;
    payerRegistrationNumber: string;
    contractNumber: string;
    invoiceNumber: string;
    invoiceValue: number;
    discountValue: number;
    presentValue: number;
    dueDate: string;
    paymentItemIndex?: number;
    paymentItemTotal?: number;
    yourNumber?: string;
    storeCode?: number;
    productCode?: number;
    creditType?: string;
    identification?: string;
    emissionDate?: string;
    index?: number;
    payerDescription?: string;
    payerAdress?: string;
    payerZipCodeAdress?: number;
    contractFee?: number;
    simulationRegistrationNumber: string;
    warrantyOptionDescription: string;
    warrantyOption: string;
    previousAdeNumber: string;
    warrantyDeadline: string;
    warrantyOptionDisplay: string;
    administratorName: string;
    administratorRegistrationNumber: string;
    quota: string;
    group: string;
}

type TColors = 'info' | 'medium' | 'success' | 'warning' | 'error';
function createCardItem(
    id: string,
    title: string,
    value: string | number,
    uppercase: 'uppercase' | 'initial',
    color: TColors
): Item {
    return { id, title, value, uppercase, color };
}

const warrantyTypeMappers: Record<string, (item: any, isZetra?: boolean) => Item[]> = {
    JudiciaryProcess: (item) => [
        createCardItem('1', 'Tipo de Processo', item?.typeOfProcess, 'uppercase', 'info'),
        createCardItem('2', 'Número do Processo', item?.number, 'initial', 'medium'),
        createCardItem(
            '3',
            'Valor da Causa',
            formatCurrencyInCents(item?.valueOfCause || 0),
            'initial',
            'success'
        ),
    ],
    HomeEquity: (item) => [
        createCardItem(
            '1',
            'Endereço',
            `${item?.addressName}, ${item?.addressNumber} ${item?.complementAddress}`,
            'initial',
            'info'
        ),
        createCardItem('2', 'CEP', item?.zipCodeAddress, 'initial', 'medium'),
        createCardItem(
            '3',
            'Registro em Cartório',
            `Registrado em ${item?.registryOffice} sob matrícula ${item?.registrationNumber}`,
            'initial',
            'success'
        ),
    ],
    Vehicle: (item) => [
        createCardItem('1', 'Modelo e Cor', `${item?.brand} (${item?.color})`, 'initial', 'info'),
        createCardItem('2', 'Placa', `${item?.board ?? 'N/D'}`, 'uppercase', 'info'),
        createCardItem(
            '3',
            'Renavam e Chassi',
            `${item?.renavam ?? 'N/D'} / ${item?.chassis ?? 'N/D'}`,
            'uppercase',
            'info'
        ),
        createCardItem(
            '4',
            'Ano de Fabricação',
            `${item?.yearOfManufacture} (modelo ${item?.yearOfModel})`,
            'initial',
            'info'
        ),
        createCardItem('5', 'Revenda', item?.sellerCompanyName, 'initial', 'info'),
        createCardItem(
            '6',
            'CNPJ do Fornecedor',
            formatDocumentNumber(item?.taxNumberOfSupplier)!,
            'initial',
            'info'
        ),
        createCardItem(
            '7',
            'CNPJ de quem efetuou a venda',
            formatDocumentNumber(item?.sellerRegistrationNumber)!,
            'initial',
            'info'
        ),
        createCardItem(
            '8',
            'Valor de Entrada',
            formatCurrencyInCents(item?.downPayment || 0),
            'initial',
            'success'
        ),
        createCardItem(
            '9',
            'Valor do Veículo à Vista',
            formatCurrencyInCents(item?.valueInCash || 0),
            'initial',
            'success'
        ),
        createCardItem(
            '10',
            'Subtotal',
            formatCurrencyInCents(item?.subtotalAmount || 0),
            'initial',
            'success'
        ),
        createCardItem(
            '11',
            'Valor Total a ser Financiado sem Impostos',
            formatCurrencyInCents(item?.totalAmountFinancedTaxFree || 0),
            'initial',
            'success'
        ),
    ],
    Factoring: (item) => [
        createCardItem('1', 'Registradora', item?.register, 'uppercase', 'info'),
        createCardItem(
            '2',
            'Tipo de Contrato',
            item?.type === 'Collateral' ? 'Garantia' : 'Troca de Titularidade',
            'initial',
            'medium'
        ),
        createCardItem(
            '3',
            'Valor Total',
            item?.splitMethodType === 'Percentage'
                ? formatMultiply(item?.totalValue) + '%'
                : formatCurrencyInCents(item?.totalValue || 0),
            'initial',
            'success'
        ),
    ],
    PublicPayroll: (item: PublicPayrollCreateModel, isZetra?: boolean) =>
        [
            item?.warrantyOption
                ? createCardItem(
                      '0',
                      'Opções da garantia',
                      warrantyDescription(item?.warrantyOption),
                      'initial',
                      'info'
                  )
                : undefined,
            createCardItem('1', 'Nome', item?.payrollAgreementName!, 'initial', 'info'),
            createCardItem('2', 'Código da Matrícula', item?.employeeCode!, 'initial', 'info'),
            item?.warrantyCode
                ? createCardItem('3', 'Código de Averbação', item?.warrantyCode, 'initial', 'info')
                : undefined,
            !isZetra
                ? createCardItem(
                      '4',
                      'Código Externo',
                      item?.agreementCode ?? 'N/D',
                      'initial',
                      'info'
                  )
                : undefined,
            item?.employmentStatusCode
                ? createCardItem(
                      '5',
                      'Código do Vínculo',
                      item?.employmentStatusCode,
                      'initial',
                      'info'
                  )
                : undefined,
            item?.employmentStatusDescription
                ? createCardItem(
                      '6',
                      'Descrição do Vínculo',
                      item?.employmentStatusDescription,
                      'initial',
                      'info'
                  )
                : undefined,
            createCardItem(
                '7',
                'Dia do Repasse',
                item?.paymentTransferDay ?? 'N/D',
                'initial',
                'info'
            ),
            item?.previousAdeNumber
                ? createCardItem('8', 'Nº ADE anterior', item?.previousAdeNumber, 'initial', 'info')
                : undefined,
            item?.warrantyDeadline
                ? createCardItem(
                      '9',
                      'Data limite de averbação',
                      formatDate(item?.warrantyDeadline),
                      'initial',
                      'info'
                  )
                : undefined,
            createCardItem(
                '10',
                'Valor da Margem',
                formatCurrencyInCents(Number(item?.paymentAmountInCents || 0)),
                'initial',
                'success'
            ),
        ].filter((p): p is Item => p !== undefined),
    InvoiceFactoring: (item) => [
        createCardItem('1', 'Pagador', item?.payerName, 'initial', 'info'),
        createCardItem(
            '2',
            'CPF/CNPJ do Pagador',
            formatDocumentNumber(item?.payerRegistrationNumber)!,
            'initial',
            'medium'
        ),
        createCardItem(
            '3',
            'Valor Presente',
            formatCurrencyInCents(Number(item?.presentValue)),
            'initial',
            'success'
        ),
    ],
    Consortium: (item) => [
        createCardItem(
            '1',
            'CNPJ do Administrador',
            formatDocumentNumber(item?.administratorRegistrationNumber)!,
            'initial',
            'info'
        ),
        createCardItem('2', 'Nome do Administrador', item?.administratorName, 'initial', 'medium'),
        createCardItem(
            '3',
            'Grupo e Cota',
            `Grupo: ${item?.group}, Cota: ${item?.quota}`,
            'initial',
            'success'
        ),
    ],
};

export function mapCardDataFromWarranty(
    item: IWarrantyReadModel | undefined,
    isZetra: boolean
): Item[] {
    if (!item) return [createCardItem('1', 'Garantia', 'Não identificado', 'uppercase', 'error')];

    const mapFunction = warrantyTypeMappers[item?.warrantyType];
    return mapFunction(item, isZetra);
}

export function mapDescriptionWarranty(item: any, isZetra?: boolean) {
    switch (item?.warrantyType) {
        case 'JudiciaryProcess':
            return `${item.typeOfProcess} nº ${item.number} - ${item.court} - ${item.claimant} vs ${
                item.claimed
            } - Valor da causa: ${formatCurrencyInCents(item.valueOfCause || 0)}`;
        case 'HomeEquity':
            return `Rua ${item.addressName}, ${item.addressNumber} ${item.complementAddress} - ${item.district} - CEP ${item.zipCodeAddress} / ${item.uf}. Registrado em ${item.registryOffice} sob matrícula ${item.registrationNumber}. Dimensões: ${item.width}x${item.length} ${item.propertyFeatures}`;
        case 'Vehicle':
            return `Modelo ${item.brand} ${item.color}, 
                Ano ${item.yearOfManufacture} (modelo ${item.yearOfModel}), 
                Placa ${item.board}
                RENAVAM ${item.renavam}, 
                ${
                    !isEmpty(item?.sellerRegistrationNumber)
                        ? `CNPJ de quem efetuou a venda ${item?.sellerRegistrationNumber}`
                        : ''
                }
            `;
        case 'Factoring':
            return `Registradora: ${item.register} | Tipo de Contrato: ${
                item.type === 'Collateral' ? 'Garantia' : 'Troca de Titularidade'
            } | Valor total: ${
                item.splitMethodType === 'Percentage'
                    ? formatMultiply(item.totalValue) + '%'
                    : formatCurrencyInCents(item.totalValue || 0)
            }
                `;
        case 'PublicPayroll':
            return `Nome: ${item?.payrollAgreementName}, 
                            Código da matrícula: ${item?.employeeCode}, 
                            ${isZetra ? '' : `Código externo: ${item?.agreementCode ?? 'N/D'}, `}
                            Dia do repasse: ${item?.paymentTransferDay ?? 'N/D'}, 
                            Valor da margem: ${formatCurrencyInCents(
                                Number(item?.paymentAmountInCents ?? 0)
                            )}${item?.warrantyCode ? "," : ""}
                              ${
                                  item?.warrantyCode
                                      ? 'Código de averbação: ' + item?.warrantyCode
                                      : ''
                              }`;
        case 'InvoiceFactoring':
            return `
                Pagador: ${item?.payerName}, 
                CPF/CNPJ: ${formatDocumentNumber(item?.payerRegistrationNumber)}, 
                valor presente: ${formatCurrencyInCents(Number(item?.presentValue))}, 
                valor nominal: ${formatCurrencyInCents(Number(item?.invoiceValue))}, 
                Contrato: ${item?.contractNumber ?? 'N/D'}`;
        case 'Consortium':
            return `
            CNPJ do administrador: ${
                formatDocumentNumber(item?.administratorRegistrationNumber) ?? 'N/D'
            },
            Nome do administrador: ${item?.administratorName ?? 'N/D'},               
            Grupo: ${item?.group ?? 'N/D'},
            Cota: ${item?.quota ?? 'N/D'}`;
        default:
            return 'Não identificado';
    }
}

export function mapTitleWarranty(item: WarrantyType) {
    switch (item) {
        case 'JudiciaryProcess':
            return `Processo Judicial`;
        case 'HomeEquity':
            return `Imóvel`;
        case 'Vehicle':
            return `Veículo`;
        case 'Factoring':
            return `Antecipação de Recebíveis`;
        case 'PublicPayroll':
            return `Consignado Público`;
        case 'InvoiceFactoring':
            return `Desconto`;
        case 'Consortium':
            return `Consórcio`;
        default:
            return 'Não identificado';
    }
}
