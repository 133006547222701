import { Stack } from '@mui/material';
import { Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';

type ProductContentProps = {
    children: React.ReactNode;
    title: string;
    description?: string;
    refetch?: () => void;
    isFetching?: boolean;
}

const theme = activeTheme();
export const ProductContent = ({ children, title, description, refetch, isFetching }: ProductContentProps) => {

    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];

    return (
        <Stack spacing={4}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Stack>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        {title}
                    </Typography>
                    {description && <Typography variant="sm" display="block">{description}</Typography>}
                </Stack>

                <Button
                    variant="text"
                    onClick={refetch}
                    size="large"
                    disabled={isFetching}
                    sx={{ color }}
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                >
                    Atualizar
                </Button>
            </Stack>

            {children}
        </Stack>
    )
}
