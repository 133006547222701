import { useGetProductsCostsDefinition } from 'contexts/wallet/Product/BillingProductContext'
import { CostsBillingParameters } from './CostsBillingParameters'
import { useState } from 'react';

export const CostsBillingParametersContainer = () => {
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
  const [page, setPage] = useState(0);
  const { data: queryData, isLoading, refetch, isFetching } = useGetProductsCostsDefinition();

  const onChangeRowsPerPage = (page: number) => {
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
    setRowsPerPage(page);
  };

  const onChangePage = (newPage: number) => setPage(newPage);

  return (
    <CostsBillingParameters {...{
      page,
      queryData,
      rowsPerPage,
      setPage: onChangePage,
      setRowsPerPage: onChangeRowsPerPage,
      isLoading,
      refetch,
      isFetching
    }} />
  )
}
