import { useState } from 'react'
import { BillingProduct } from './BillingProduct'
import { useGetBillingProduct } from 'contexts/wallet/Product/BillingProductContext';

export const BillingProductContainer = () => {
    const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [page, setPage] = useState(0);
    const { data: queryData, isLoading, refetch, isFetching } = useGetBillingProduct();

    const onChangeRowsPerPage = (page: number) => {
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
        setRowsPerPage(page);
    };

    const onChangePage = (newPage: number) => setPage(newPage);

    return (
        <BillingProduct {...{
            page,
            queryData,
            rowsPerPage,
            setPage: onChangePage,
            setRowsPerPage: onChangeRowsPerPage,
            isLoading,
            refetch,
            isFetching
        }} />
    )
}
