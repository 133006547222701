import { Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import InstallmentFormHeader from './InstallmentFormHeader'
import { useCancelInstallmentMutation, useDownloadPdfInstallmentMutation, useGetInstallmentById, useRegisterInstallmentMutation, useTransferInstallmentMutation, useUpdateCommunicationSettingByInstallmentMutate } from 'contexts/wallet/Installment/installmentContext'
import { InstallmentFormSkeleton } from 'components/Skeleton/InstallmentFormSkeleton'
import { Alert, CalendarIcon, CallingIcon, ChatLineIcon, DealIcon, Drawer, InfoIcon, Modal, PenIcon, PinIcon, RulerIcon, UserCircleIcon } from '@uy3/web-components'
import { DetailsForm, ItemProps } from 'pages/WalletManagement/Billing/BillingForm/DetailsForm'
import { isValidString, nameAndRegistrationNumber } from 'helpers/formats/StringFormats'
import { GridMenuIcon } from '@mui/x-data-grid'
import { Categories } from 'components/Categories/Categories'
import { RefreshProgress } from 'components/RefreshProgress'
import { InsatallmentBillingsList, InstallmentBillingsListRef } from './InsatallmentBillingsList'
import { useRef, useState } from 'react'
import Toast from 'components/Toast/Toast'
import { ApiResponseError, showSuccessToast, toastState, ToastType, useApiRequest } from 'contexts/apiRequestContext'
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler'
import { TransferFormContainer } from '../Drawers/TransferForm/TransferFormContainer'
import { RegisterFormContainer } from '../Drawers/RegisterForm/RegisterFormContainer'
import { FieldValues } from 'react-hook-form'
import { UpdateCommunicationSettingContainer } from '../Drawers/UpdateCommunicationSetting/UpdateCommunicationSettingContainer'

export const InstallmentFormContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [handleAction, setHandleAction] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const { handleErrorException } = useApiRequest();
    const onClose = () => setHandleAction(undefined);

    const { installmentId } = useParams();
    const { data: installmentData, isLoading, refetch, isFetching } = useGetInstallmentById(installmentId!);

    const onSuccessCustom = (message: string, description?: string) => {
        showSuccessToast(message, description, setToast);
        onClose();
        handleRefetch();
    }

    const installmentBillingsListRef = useRef<InstallmentBillingsListRef>(null);

    const handleRefetch = () => {
        refetch()
        installmentBillingsListRef.current?.refetch();
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)
    const { mutateAsync: transferMutateAsync, isLoading: isLoadingTransfer } = useTransferInstallmentMutation(
        {
            id: installmentId,
            onSuccess: () => onSuccessCustom("Transferência realizada com sucesso"),
            onError
        });

    const { mutateAsync: cancelMutateAsync, isLoading: isLoadingCancel } = useCancelInstallmentMutation(() => onSuccessCustom("Sucesso ao cancelar parcela"), onError);
    const { mutateAsync: registerMutateAsync, isLoading: isLoadingRegister } = useRegisterInstallmentMutation(installmentId!, () => onSuccessCustom("Parcela registrada com sucesso"), onError);
    const { mutateAsync: updateCommunicationMutateAsync, isLoading: isUpdateCommunication } = useUpdateCommunicationSettingByInstallmentMutate(installmentId!, () => onSuccessCustom("Régua de comunicação atualizada com sucesso"), onError);
    const { mutateAsync: downloadPdfMutateAsync, isLoading: isLoadingDownloadPdf } = useDownloadPdfInstallmentMutation(installmentId!, () => onSuccessCustom("Download realizado com sucesso"),
        onError);

    const handleRegisterInstallment = async (values: FieldValues) => {
        await registerMutateAsync(
            {
                enableRegisterOverDue: values?.enableRegisterOverDue
            });
    }

    const payer = installmentData?.payer!;

    const secondInstructionDebtCollection = installmentData?.secondInstructionDebtCollection ?? 0;
    const enableAlertDebtCollection = installmentData?.firstInstructionDebtCollection === "06" && secondInstructionDebtCollection > 0;
    const categories = installmentData?.tags ?? [];

    const detailsData = [
        {
            title: "Código do convênio",
            value: installmentData?.walletCode.toString(),
            Icon: PenIcon,
            disable: false
        },
        {
            title: "Número da operação",
            value: `#${installmentData?.operation}`,
            Icon: PinIcon,
            disable: !isValidString(String(installmentData?.operation))
        },
        {
            title: "Status",
            value: installmentData?.statusDisplay,
            Icon: InfoIcon,
            disable: false
        },

        {
            title: "Criado em",
            value: new Date(installmentData?.createdAt as string).toLocaleDateString('pt-BR'),
            Icon: CalendarIcon
        },
        {
            title: "Descrição",
            value: installmentData?.description,
            Icon: ChatLineIcon
        },
        {
            title: "Devedor",
            value: nameAndRegistrationNumber(payer?.name, payer?.registrationNumber?.toString()),
            Icon: DealIcon
        },
        {
            title: "Sacador avalista",
            value: nameAndRegistrationNumber(installmentData?.guarantor?.name!, installmentData?.guarantor?.registrationNumber!),
            Icon: UserCircleIcon
        },
        {
            title: "Quantidade de parcelas",
            value: installmentData?.billingCount?.toString(),
            Icon: GridMenuIcon
        },
        {
            title: "Régua de cobrança",
            value: installmentData?.billingSettingDisplay?.toString(),
            Icon: RulerIcon
        },
        {
            title: "Régua de comunicação",
            value: installmentData?.communicationSettingDisplay?.toString(),
            Icon: CallingIcon
        },

    ] as ItemProps[];

    if (isLoading) {
        return <Stack mt={-5}>
            <InstallmentFormSkeleton />
        </Stack>
    }

    return (
        <Stack spacing={2} mb={10}>
            <Toast toast={toast} setToast={setToast} />

            <InstallmentFormHeader {...{
                isFetching: false,
                refetch: handleRefetch,
                downloadPdf: downloadPdfMutateAsync,
                isLoadingDownloadPdf,
                setHandleAction: setHandleAction,
                installmentData: installmentData!,
                redirectFromBilling: () => navigate(`/gestao-carteira/cobrancas?installmentId=${installmentId}`)
            }} />

            <RefreshProgress refreshing={(isFetching && !isLoading) || isLoadingDownloadPdf} />

            {enableAlertDebtCollection && (
                <Alert
                    severity='info'
                    text={`Parcelamento possui instrução de protesto automático ${secondInstructionDebtCollection} dias após o vencimento.`}
                />)}

            <DetailsForm
                list={detailsData}
                isLoading={isLoading}
            />

            {categories?.length > 0 && <Categories categoryList={categories ?? []} />}
            <InsatallmentBillingsList ref={installmentBillingsListRef}/>

            <Drawer
                {...{
                    anchor: "right",
                    open: ["transfer"].includes(handleAction!),
                    title: `Transferir parcela #${installmentData?.walletCode ?? ""}`,
                    description: "Realize a transferência informando o código de carteira abaixo.",
                    onClose,
                    children: <TransferFormContainer {...{
                        isLoading: isLoadingTransfer,
                        onClose,
                        onSubmit: (values) => transferMutateAsync(values),
                        rowData: installmentData!
                    }} />
                }} />

            <Drawer
                {...{
                    anchor: "right",
                    open: ["updateCommunicationSetting"].includes(handleAction!),
                    title: `Atualizar régua de comunicação`,
                    description: "Atuelize a régua de comunicação do parcelamento abaixo.",
                    onClose,
                    children: <UpdateCommunicationSettingContainer
                        {...{
                            isLoading: isUpdateCommunication,
                            onClose,
                            walletsCode: [installmentData?.walletCode!],
                            onSubmit: (values) => {
                                updateCommunicationMutateAsync({
                                    communicationSettingId: values?.communicationSettingId
                                })
                            },
                            rowData: installmentData!
                        }} />
                }} />

            <Modal
                {...{
                    open: ["cancel"].includes(handleAction!),
                    title: `Cancelar parcela #${installmentData?.walletCode ?? ""}`,
                    description: "Tem certeza que deseja cancelar essa parcela?",
                    onClose,
                    children: (
                        <GenericActionHandler
                            handleSubmit={() => cancelMutateAsync(installmentId)}
                            onClose={onClose}
                            isLoading={isLoadingCancel}
                            isModal
                        />
                    )
                }} />

            <Modal
                {...{
                    open: ["register"].includes(handleAction!),
                    title: `Regisrar parcelamento`,
                    description: "Tem certeza que deseja cancelar essa parcela?",
                    onClose,
                    children: (
                        <RegisterFormContainer {...{
                            installmentData: installmentData!,
                            isLoading: isLoadingRegister,
                            onClose,
                            onSubmit: handleRegisterInstallment
                        }} />
                    )
                }} />

        </Stack>

    )
}
