import { Grid, IconButton, Stack } from '@mui/material';
import {
    DataTable,
    SearchIcon,
    BankSlipIcon,
    ApproveIcon,
    RejectIcon,
    CancelIcon,
    Typography,
} from '@uy3/web-components';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { formatBankAccountBeneficiary } from 'helpers/formats/BankAccount';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { sumRowsSelectedDataTable } from 'helpers';

type TableTransferProps = {
    handleTransferReceipt: (values: any) => void;
    queryData: any;
    setStepActionTransfer: (step: string) => void;
    rowsPerPage: any;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (page: number) => void;
    page: number;
    setTransferAndPixInfo: (rowData: GridColDef) => void;
    setTransferId: (userId: any) => void;
    rowsSelected: GridRowId[];
    handleSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
};

const TableTransfer = ({
    handleTransferReceipt,
    queryData,
    setPage,
    setRowsPerPage,
    setStepActionTransfer,
    setTransferId,
    rowsPerPage,
    page,
    setTransferAndPixInfo,
    rowsSelected,
    handleSelectionModelChange
}: TableTransferProps) => {
    const hasRowsSelected = rowsSelected?.length > 0;
    const totalValueAMount = sumRowsSelectedDataTable(queryData?.data, rowsSelected as string[], ['amount']); 

    const columnsTransferDataList: GridColDef[] = [
        {
            field: 'transferDate',
            headerName: 'Data da ocorrência',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => {
                const formattedDate = new Date(value).toLocaleDateString('pt-BR');
                return value && formattedDate;
            }
        },
        {
            field: 'bankAccountBeneficiary.name',
            headerName: 'Favorecido',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues?.row?.bankAccountBeneficiary?.name,
        },
        {
            field: 'bankAccountBeneficiary.registrationNumber',
            headerName: 'CPF/CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) =>
                formatDocumentNumber(cellValues?.row?.bankAccountBeneficiary?.registrationNumber),
        },
        {
            field: 'bankAccountBeneficiary',
            headerName: 'Dados da conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                let row = cellValues?.row?.bankAccountBeneficiary;
                return formatBankAccountBeneficiary(row);
            },
        },
        {
            field: 'amount',
            headerName: 'Valor da transferência',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => `${formatCurrencyInCents(cellValues.value)}`,
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues.value,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                const isPedingApprove = cellValues.row?.statusDisplay === 'Aguardando aprovação';
                const showBtnCancel = ['Em processamento', 'Pendente', 'Agendado'].includes(cellValues.row?.statusDisplay);
                const isEffective = cellValues?.row?.statusDisplay === 'Efetivada';

                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton onClick={() => setTransferAndPixInfo(cellValues?.row)}>
                            <SearchIcon sx={iconSx} />
                        </IconButton>
                        {isPedingApprove && (
                            <RowActions
                                listButtons={[
                                    {
                                        action: () => {
                                            setStepActionTransfer('approveTransfer');
                                            setTransferId(cellValues?.row?.id);
                                        },
                                        disabled: rowsSelected?.length > 0,
                                        icon: <ApproveIcon />,
                                        label: 'Aprovar',
                                    },
                                    {
                                        action: () => {
                                            setStepActionTransfer('rejectTransfer');
                                            setTransferId(cellValues?.row?.id);
                                        },
                                        disabled: rowsSelected?.length > 0,
                                        icon: <RejectIcon />,
                                        label: 'Rejeitar',
                                    },
                                ]}
                            />
                        )}

                        {showBtnCancel && (
                            <RowActions
                                listButtons={[
                                    {
                                        action: () => {
                                            setStepActionTransfer('cancelTransfer');
                                            setTransferId(cellValues?.row?.id);
                                        },
                                        disabled: rowsSelected?.length > 0,
                                        icon: <CancelIcon />,
                                        label: 'Cancelar',
                                    },
                                ]}
                            />
                        )}

                        {isEffective && (
                            <IconButton onClick={() => handleTransferReceipt(cellValues)}>
                                <BankSlipIcon sx={iconSx} />
                            </IconButton>
                        )}
                    </Stack>
                );
            },
        },
    ];

    return (
        <Stack position='relative'>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <BankAccountFiltersContainer typeFilter="transferAndPix" />,
                }}
                columns={columnsTransferDataList}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                checkboxSelection={true}
                keepNonExistentRowsSelected
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={handleSelectionModelChange}
            />

            {hasRowsSelected && (
                <Typography
                    variant="sm"
                    color="#6F6F6F"
                    style={{
                        position: 'absolute',
                        bottom: 14,
                        left: rowsSelected!?.length >= 10 ? 230 : 220
                    }}
                >
                    Valor total: <strong>{formatCurrencyInCents(totalValueAMount)}</strong>
                </Typography>
            )}
        </Stack>
    );
};

export default TableTransfer;
